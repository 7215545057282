import Lottie from "lottie-react";
import "./App.css";
import lottiefile from "./NmINYDVtAp.json";
import image from "./HF-Logo-AR.png"
function App() {
  return (
    <div className="App">
      <Lottie className="lottie_json" animationData={lottiefile} autoplay loop />
      <h1 style={{color: "#b71f3c"}}>Inmaeya Developmental Portal</h1>
      {/* <img src={image} /> */}
    </div>
  );
}

export default App;
